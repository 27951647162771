import React, {
  createContext, useContext, useState, 
} from 'react';

const NotifyContext = createContext({
  notification: '',
  setNotification: () => {},
  close: false,
  setClose: () => {},
});

function NotifyProvider({ children }) {
  const [notification, setNotification] = useState();
  const [close, setClose] = useState(false);

  const values = {
    notification, setNotification, close, setClose,
  };

  return (
    <NotifyContext.Provider value={values}>
      {children}
    </NotifyContext.Provider>
  );
}

export { NotifyProvider, NotifyContext };

export const useNotify = () => useContext(NotifyContext);
